import React from 'react';
import { AnyImage } from 'src/components';

const Component: React.FCX = ({ className }) => (
  <header id='site-header' className={className}>
    <h1 id="header-logo"><AnyImage filename='aces-corp-logo-transparent-back.png' alt="Aces株式会社" /></h1>
    {/* <nav>
      <ul>
        <li>
          <Link to='/'>Top</Link>
        </li>
        <li>
          <Link to='/about'>About</Link>
        </li>
        <li>
          <Link to='/contact'>Contact</Link>
        </li>
        <li>
          <Link to='/404'>404</Link>
        </li>
        <li>
          <ModeButton />
        </li>
      </ul>
    </nav> */}
  </header>
);

export default Component;
