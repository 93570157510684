module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-relative-images",{"resolve":"gatsby-remark-images","options":{"maxWidth":1400,"quality":90,"linkImagesToOriginal":true}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://gatsby-starter-hpp.netlify.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149661454-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aces株式会社","short_name":"Aces株式会社","description":"Aces(エーシーズ)株式会社は、eSports（eスポーツ）業界の可能性を信じ支援をしていきたいと考えています。Esportsに関わる選手のためのアプリケーションや観客のためのサービスなどの開発を予定しています。その他にも業務用システムやWebサービス・アプリケーションなどの制作から動画コンテンツの制作・コンサルティング等、インターネットを用いた様々な業務を行っております。","Scope":"/","start_url":"/?utm_source=homescreen","background_color":"#09090f","theme_color":"#09090f","display":"standalone","icon":"./assets/aces-corp-logo-icon-square.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"30e11d82d9a4f8098a981b3a609a8643"},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":".gatsby"},
    }]
