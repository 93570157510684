import React from 'react';

import styled from '@emotion/styled';

// this form works only Netlify

const Component: React.FCX = ({ className }) => (
  <form className={className} name='contact' method='POST' data-netlify='true' data-netlify-honeypot='bot-field'>
    <p className="form-info">*は必須事項です</p>
    <input type='hidden' name='form-name' value='contact' />
    <input type='hidden' name='bot-field' />
    <div className='form-group'>
      <label htmlFor='name'>
        ご氏名
        <abbr title='required'>*</abbr>
      </label>
      <input
        type='text'
        className='form-control'
        name='name'
        placeholder='your name'
        maxLength={30}
        minLength={2}
        required
        autoComplete='name'
      />
    </div>

    <div className='form-group'>
      <label htmlFor='mail'>
        メールアドレス
        <abbr title='required'>*</abbr>
      </label>
      <input
        type='email'
        className='form-control'
        name='email'
        placeholder='your e-mail'
        pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
        required
        autoComplete='email'
      />
    </div>

    <div className='form-group'>
    <label htmlFor='message'>
      本文
      <abbr title='required' />
    </label>
      <textarea
        name='content'
        rows={8}
        className='form-control'
        required
      />
    </div>

    <button type='submit' id='submit-btn'>送信</button>
  </form>
);

const StyledComponent = styled(Component)`
  position: relative;

  > label {
    display: block;
    width: 100%;
    max-width: 300px;
    padding: 1rem 0;

    > abbr {
      text-decoration: none;
    }

    > textarea {
      width: 100%;
      color: #000000;
      border: 2px solid #00000055;
      transition: border 0.5s;
      :focus {
        border: 2px solid #00000055;
        outline: none;
      }

      > input[type='text'],
      > input[type='email'] {
        width: 100%;
        padding: 0.3em;
        border: none;
        border-bottom: 2px solid #000000;
        transition: 0.5s;
        :focus {
          border-bottom: 2px solid #00000055;
          outline: none;
        }
      }
    }

    > button {
      padding: 0.5rem 1rem;
      color: #000000;
      border: 2px solid #00000055;
      border-radius: 5px;
    }
  }

  .form-group {
    label {
      display: block;
      margin-bottom: 1rem;
    }

    input,
    textarea {
      margin-bottom: 3rem;
      width: 100%;
    }
  }

  @media screen and (max-width: 1100px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-height: 430px) {
  }
`;

export default StyledComponent;
