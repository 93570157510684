import React from 'react';
import { AnyImage, SEO, NetlifyForm } from 'src/components';
import 'src/styles/index.scss';
import { baseStyle } from 'src/styles';

import styled from '@emotion/styled';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Settings, ResponsiveObject } from "react-slick"
import Slider from "react-slick"
import Canvas from "../components/catch-canvas"

const smallSliderResponsive: ResponsiveObject = {
  breakpoint: 480,
  settings: {
    slidesToShow: 1,
    centerPadding: '30px'
  }
}

const mediumSliderResponsive: ResponsiveObject = {
  breakpoint: 768,
  settings: {
    slidesToShow: 2,
    centerPadding: '20px'
  }
}

const largeSliderResponsive: ResponsiveObject = {
  breakpoint: 1024,
  settings: {
    slidesToShow: 3,
    centerPadding: '30px'
  }
}

const xlargeSliderResponsive: ResponsiveObject = {
  breakpoint: 1800,
  settings: {
    slidesToShow: 3,
    centerPadding: '80px'
  }
}

const slickSetting: Settings = {
  arrows: false,
  autoplay: true,
  dots: true,
  touchMove: true,
  swipeToSlide: true,
  swipe: true,
  lazyLoad: "progressive",
  draggable: true,
  // rows: 3,
  slidesToShow: 4,
  speed: 500,
  infinite: true,
  centerMode: true,
  centerPadding: '80px',
  adaptiveHeight: false,
  responsive: [
    smallSliderResponsive,
    mediumSliderResponsive,
    largeSliderResponsive,
    xlargeSliderResponsive
  ]
}

const Component: React.FCX = ({ className }) => (
  <main className={className}>
    <div id='top-catch__wrap'>
      <h1>挑戦者の<br />“切り札”として</h1>
      {/* <AnyImage filename='top-catch-1.jpg' alt='Aces（エーシーズ）株式会社' /> */}
      <Canvas />
    </div>

    <section id='about' className='top-section'>
      <h2 className="">
        <span>Aces.</span><span className="subtitle">常に“切り札”であり続ける</span>
        <div className="skew-background">
          <div className="skew-background2"></div>
        </div>
      </h2>
      <div className="container-fluid pt-0 pb-0">
        <div className="about__text-wrap">
          <p className="about__text-container">
            <span className="text-title">お客様は挑戦者である。</span><br />
            なにに挑戦しようとしているのか。お客様が望むプロダクトはなにか。そのプロダクトの本当の目的はなにか。<br />
            ご依頼をただこなすのではなく、プロダクト、プロジェクトの真髄を見極め、覚悟を持って課題解決に取り組む。<br />
            そしてなによりも、高品質なプロダクトを最速で製作する。<br />
            プログラムは、何かを達成するためのツールなのだから。<br />
            挑戦者に寄り添い、新規事業やMVP開発、継続的開発の“切り札”として、Aces株式会社はあり続ける。
          </p>
          <div className="image-background"></div>
        </div>
      </div>
    </section>

    <section id='services' className='top-section'>
      <h2 className="reverse">
        <span>Services.</span><span className="subtitle">挑戦者を支える</span>

        <div className="skew-background"></div>
        <div className="skew-background2"></div>
      </h2>
      <div className="container-fluid pt-0 pb-0">
        <Slider {...slickSetting}>
          <div>
            <div className="slide-item">
              <div className="service-image service-image__mvp"></div>
              <h3>高速MVP開発</h3>
            </div>
          </div>
          <div>
            <div className="slide-item">
              <div className="service-image service-image__efficiency"></div>
              <h3>業務効率化アプリ開発</h3>
            </div>
          </div>
          <div>
            <div className="slide-item">
              <div className="service-image service-image__application"></div>
              <h3>Webアプリケーション開発</h3>
            </div>
          </div>
          <div>
            <div className="slide-item">
              <div className="service-image service-image__smartphone"></div>
              <h3>スマートフォンアプリ開発</h3>
            </div>
          </div>
          <div>
            <div className="slide-item">
              <div className="service-image service-image__web"></div>
              <h3>Webサイト製作</h3>
            </div>
          </div>
          <div>
            <div className="slide-item">
              <div className="service-image service-image__media"></div>
              <h3>自社メディア・サービス製作、運用</h3>
            </div>
          </div>
        </Slider>
      </div>
    </section>

    <section id='works' className='top-section'>
      <h2 className=""><span>Works.</span><span className="subtitle">挑戦者を支える</span></h2>
      <div className="container-fluid pt-0 pb-0">
        <Slider {...slickSetting}>
          <div>
            <div className="slide-item">
              <div className="service-image work-image__book"></div>
              <h3>小説販売プラットフォームの構築</h3>
            </div>
          </div>
          <div>
            <div className="slide-item">
              <div className="service-image work-image__seek"></div>
              <h3>職業紹介サービス・スマホアプリ</h3>
            </div>
          </div>
          <div>
            <div className="slide-item">
              <div className="service-image work-image__party"></div>
              <h3>婚活マッチングサイト</h3>
            </div>
          </div>
          <div>
            <div className="slide-item">
              <div className="service-image work-image__staff-app"></div>
              <h3>店舗用従業員管理システム</h3>
            </div>
          </div>
          <div>
            <div className="slide-item">
              <div className="service-image work-image__mvp"></div>
              <h3>MVPプロダクトの開発</h3>
            </div>
          </div>
          <div>
            <div className="slide-item">
              <div className="service-image work-image__sp"></div>
              <h3>上場企業保有のスマホアプリ</h3>
            </div>
          </div>
        </Slider>
      </div>
    </section>

    <section id='company' className='top-section'>
      <h2 className="reverse">Company.<span className="subtitle">会社概要</span></h2>
      <div className="container-fluid pt-0 pb-0">
        <div className="company-block">
          <dl>
            <dt>会社名</dt>
            <dd>Aces株式会社（エーシーズ株式会社）</dd>
          </dl>
        </div>
        <div className="company-block">
          <dl>
            <dt>本店所在地</dt>
            <dd>東京都豊島区上池袋3-33-22</dd>
          </dl>
        </div>
        <div className="company-block">
          <dl>
            <dt>事業内容</dt>
            <dd>・Webサイトシステム及びウェブコンテンツの企画、制作、運営を主軸としたWebソリューション事業<br />・スマートフォンアプリケーションの開発事業<br />・自社サービス、メディアの運営事業</dd>
          </dl>
        </div>
        <div className="company-block">
          <dl>
            <dt>代表取締役</dt>
            <dd>白石 敬亮</dd>
          </dl>
        </div>
      </div>
    </section>

    <section id='contact' className='top-section'>
      <h2>
        <span>Contact.</span><span className="subtitle">お問い合わせ</span>
        <div className="skew-background"></div>
        <div className="skew-background2"></div>
      </h2>
      <div className="container-fluid pt-0 pb-0">
        <div className="contact__form-wrap">
          <NetlifyForm />
        </div>
      </div>
    </section>
  </main>
);

const StyledComponent = styled(Component)`
  ${baseStyle}
`;

export default ({ path }: { path: string }): JSX.Element => (
  <>
    <SEO title='Top' pathname={path} />
    <StyledComponent />
  </>
);
