import React, { Component } from 'react';

export default class Canvas extends Component {
  constructor() {
    super();

    this.vertexCount = 10000;
    this.vertexSize = 3;
    this.oceanWidth = 204;
    this.oceanHeight = 1000;
    this.gridSize = 32;
    this.waveSize = 16;
    this.perspective = 100;
    this.depth = (this.vertexCount / this.oceanWidth * this.gridSize);
    this.frame = 0;

    this.vertices = []
  }

  componentDidMount = () => {
    this.context = document.createElement('canvas').getContext('2d')
    this.postctx = document.getElementById('top-catch__wrap').appendChild(document.createElement('canvas')).getContext('2d')
    this.canvas = this.context.canvas
    // this.postctx = this.refs.secondCanvas.getContext('2d')
    this.generateDots()
    this.loop()
  }

  sin = (num) => {
    return Math.sin(num);
  }

  cos = (num) => {
    return Math.cos(num);
  }

  tan = (num) => {
    return Math.tan(num);
  }

  PI = () => {
    return Math.PI()
  }

  generateDots = () => {
    for (let i = 0; i < this.vertexCount; i++) {
      let x = i % this.oceanWidth
      let y = 0
      let z = i / this.oceanWidth >> 0
      let offset = this.oceanWidth / 2
      this.vertices.push([(-offset + x) * this.gridSize, y * this.gridSize, z * this.gridSize])
    }
  }

  loop = () => {
    let c = document.createElement('canvas').getContext('2d')
    let postctx = document.getElementById('top-catch__wrap').appendChild(document.createElement('canvas')).getContext('2d')
    let canvas = c.canvas
    let vertices = []

    this.canvas = canvas
    this.postctx = postctx
    this.context = c

    // Effect Properties
    let vertexCount = 10000
    let vertexSize = 2
    let oceanWidth = 204
    let oceanHeight = -400
    let gridSize = 32;
    let waveSize = 100;
    let perspective = 300;

    // Common variables
    let depth = (vertexCount / oceanWidth * gridSize)
    let frame = 0
    let {
      sin,
      cos,
      tan,
      PI
    } = Math

    // Render loop
    let loop = () => {
      let rad = sin(frame / 100) * PI / 20
      let rad2 = sin(frame / 50) * PI / 10
      frame++
      if (postctx.canvas.width !== postctx.canvas.offsetWidth || postctx.canvas.height !== postctx.canvas
        .offsetHeight) {
        postctx.canvas.width = canvas.width = postctx.canvas.offsetWidth
        postctx.canvas.height = canvas.height = postctx.canvas.offsetHeight
      }


      c.fillStyle = `hsl(200deg, 100%, 2%)` //背景
      c.fillRect(0, 0, canvas.width, canvas.height)
      c.save()
      c.translate(canvas.width / 2, canvas.height / 2)

      c.beginPath()
      vertices.forEach((vertex, i) => {
        let ni = i + oceanWidth
        let x = vertex[0] - frame % (gridSize * 2)
        let z = vertex[2] - frame * 2 % gridSize + (i % 2 === 0 ? gridSize / 2 : 0)
        let wave = (cos(frame / 45 + x / 50) - sin(frame / 20 + z / 50) + sin(frame / 30 + z * x / 10000))
        let y = vertex[1] + wave * waveSize
        let a = Math.max(0, 1 - (Math.sqrt(x ** 2 + z ** 2)) / depth)
        let tx, ty, tz

        y -= oceanHeight

        // Transformation variables
        tx = x
        ty = y
        tz = z

        // Rotation Y
        tx = x * cos(rad) + z * sin(rad)
        tz = -x * sin(rad) + z * cos(rad)

        x = tx
        y = ty
        z = tz

        // Rotation Z
        tx = x * cos(rad) - y * sin(rad)
        ty = x * sin(rad) + y * cos(rad)

        x = tx;
        y = ty;
        z = tz;

        // Rotation X

        ty = y * cos(rad2) - z * sin(rad2)
        tz = y * sin(rad2) + z * cos(rad2)

        x = tx;
        y = ty;
        z = tz;

        x /= z / perspective
        y /= z / perspective



        if (a < 0.01) return
        if (z < 0) return


        c.globalAlpha = a
        c.fillStyle = `hsl(${180 + wave * 20}deg, 100%, 50%)`
        c.fillRect(x - a * vertexSize / 2, y - a * vertexSize / 2, a * vertexSize, a * vertexSize)
        c.globalAlpha = 1
      })
      c.restore()

      // Post-processing
      postctx.drawImage(canvas, 0, 0)

      postctx.globalCompositeOperation = "screen"
      postctx.filter = 'blur(16px)'
      postctx.drawImage(canvas, 0, 0)
      postctx.filter = 'blur(0)'
      postctx.globalCompositeOperation = "source-over"
      console.log('あ', this.context)

      requestAnimationFrame(loop)
    }

    // Generating dots
    for (let i = 0; i < vertexCount; i++) {
      let x = i % oceanWidth
      let y = 0
      let z = i / oceanWidth >> 0
      let offset = oceanWidth / 2
      vertices.push([(-offset + x) * gridSize, y * gridSize, z * gridSize])
    }
    loop()
  }

  // loop = () => {
  //   let rad = this.sin(this.frame / 100) * this.PI / 20
  //   let rad2 = this.sin(this.frame / 50) * this.PI / 10
  //   this.frame++
  //   if (this.postctx.canvas.width !== this.postctx.canvas.offsetWidth || this.postctx.canvas.height !== this.postctx.canvas.offsetHeight) {
  //     this.postctx.canvas.width = this.canvas.width = this.postctx.canvas.offsetWidth
  //     this.postctx.canvas.height = this.canvas.height = this.postctx.canvas.offsetHeight
  //   }

  //   this.context.fillStyle = `hsl(200deg, 100%, 2%)`
  //   this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)
  //   this.context.save()
  //   this.context.translate(this.canvas.width / 2, this.canvas.height / 2)

  //   this.context.beginPath()

  //   this.vertices.forEach((vertex, i) => {
  //     let ni = i + this.oceanWidth
  //     let x = vertex[0] - this.frame % (this.gridSize * 2)
  //     let z = vertex[2] - this.frame * 2 % this.gridSize + (i % 2 === 0 ? this.gridSize / 2 : 0)
  //     let wave = (this.cos(this.frame / 45 + x / 50) - this.sin(this.frame / 20 + z / 50) + this.sin(this.frame / 30 + z * x / 10000))
  //     let y = vertex[1] + wave * this.waveSize
  //     let a = Math.max(0, 1 - (Math.sqrt(x ** 2 + z ** 2)) / this.depth)
  //     let tx, ty, tz

  //     y -= this.oceanHeight

  //     // Transformation variables
  //     tx = x
  //     ty = y
  //     tz = z

  //     // Rotation Y
  //     tx = x * this.cos(rad) + z * this.sin(rad)
  //     tz = -x * this.sin(rad) + z * this.cos(rad)

  //     x = tx
  //     y = ty
  //     z = tz

  //     // Rotation Z
  //     tx = x * this.cos(rad) - y * this.sin(rad)
  //     ty = x * this.sin(rad) + y * this.cos(rad)

  //     x = tx;
  //     y = ty;
  //     z = tz;

  //     // Rotation X

  //     ty = y * this.cos(rad2) - z * this.sin(rad2)
  //     tz = y * this.sin(rad2) + z * this.cos(rad2)

  //     x = tx;
  //     y = ty;
  //     z = tz;

  //     x /= z / this.perspective
  //     y /= z / this.perspective



  //     if (a < 0.01) return
  //     if (z < 0) return


  //     this.context.globalAlpha = a
  //     this.context.fillStyle = `hsl(${180 + wave * 20}deg, 100%, 50%)`
  //     this.context.fillRect(x - a * this.vertexSize / 2, y - a * this.vertexSize / 2, a * this.vertexSize, a * this.vertexSize)
  //     this.context.globalAlpha = 1
  //   })
  //   this.context.restore()

  //   // Post-processing
  //   this.postctx.drawImage(this.canvas, 0, 0)

  //   this.postctx.globalCompositeOperation = "screen"
  //   this.postctx.filter = 'blur(16px)'
  //   this.postctx.drawImage(this.canvas, 0, 0)
  //   this.postctx.filter = 'blur(0)'
  //   this.postctx.globalCompositeOperation = "source-over"

  //   console.log('this.loop method')
  //   requestAnimationFrame(this.loop)
  // }

  render() {
    return (<>
      </>
    );
  }
}
