import React from 'react';
import { AnyImage } from 'src/components';

const year = new Date().getFullYear();

const Component: React.FCX = ({ className }) => (
  <footer id="site-footer" className={className}>
    <div id="footer-logo">
      <div id="footer__image-wrap">
        <AnyImage filename='aces-corp-logo-white.png' alt="Aces株式会社" />
      </div>
    </div>
    <p className="copyright">Copyright © {year} Aces Inc. All Rights Reserved.</p>
  </footer>
);



export default Component;
