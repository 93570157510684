import { css } from '@emotion/core';

export default css`
  flex: 1;

  width: 100%;
  // max-width: 1400px;
  // padding: 0 2vw;
  padding: 0;

  margin: 0 auto;

  .row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .col-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }

  > h1 {
    padding-bottom: 3rem;
    font-size: 4rem;
  }

  @media screen and (max-width: 1100px) {
  }
  @media screen and (max-width: 768px) {
    .row {
      .col-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-height: 430px) {
  }
`;
